import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import '../css/App.css';
import '../css/mobile.css'
const IsolateContent = () => {

    return (
        <>

            <Outlet />
            
        </>
    );
};


export default IsolateContent;