import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { getUser } from './httpcalls/authThunk';
import loadable from '@loadable/component';

const Homepage = loadable(() => import('./mainapp/homepage'));
const MainDirectory = loadable(() => import('./mainapp/mainDirectory'));
const Login = loadable(() => import('./auth/login'));
const LandingPage = loadable(() => import('./landingPage/landingPage'));
const Launch = loadable(() => import('./mainapp/onboarding/launch'));
const Student = loadable(() => import('./mainapp/onboarding/student/student'));
const Admin = loadable(() => import('./mainapp/onboarding/admin/admin'));
const LoginForm = loadable(() => import('./mainapp/onboarding/login'));
const ScanCode = loadable(() => import('./mainapp/onboarding/student/s_scanCode'));
const SignupForm = loadable(() => import('./mainapp/onboarding/signup'));
const Verification = loadable(() => import('./mainapp/onboarding/verification'));
const ResetPassword = loadable(() => import('./mainapp/onboarding/resetPassword'));
const EnterNewPassword = loadable(() => import('./mainapp/onboarding/newPassword'));
const AdminPage = loadable(() => import('./mainapp/manager/admin'));
const Overview = loadable(() => import('./mainapp/manager/overview/overview'));
const CampaignView = loadable(() => import('./mainapp/manager/overview/o_campaigns'));
const ManageView = loadable(() => import('./mainapp/manager/overview/o_manage'));
const RequestsView = loadable(() => import('./mainapp/manager/overview/o_requests'));
const NewCampaign = loadable(() => import('./mainapp/manager/new/newCampaign'));
const GlobalLoadingOverlay = loadable(() => import('./components/globalLoadingOverlay'));
const ThunkNavigation = loadable(() => import('./components/naviagtion'));
const NewSchool = loadable(() => import('./mainapp/manager/new/newSchool'));
const Campaign = loadable(() => import('./mainapp/campaign/campaign'));
const NewPrize = loadable(() => import('./mainapp/manager/new/newPrize'));
const JoinCampaign = loadable(() => import('./mainapp/manager/new/joinCampaign'));
const ErrorPage = loadable(() => import('./mainapp/errorpage'));
const Payments = loadable(() => import('./mainapp/manager/payments/payments'));
const Winners = loadable(() => import('./mainapp/manager/winners'));
const Completed = loadable(() => import('./mainapp/campaign/components/completed'));
const PreRelease = loadable(() => import('./mainapp/campaign/components/prerelease'));
const Redirect = loadable(() => import('./helpers/redirect'));
const NewProfile = loadable(() => import('./mainapp/manager/new/newProfile'));
const GDPR = loadable(() => import('./landingPage/gdpr'));
const RenderAdminPanel = loadable(() => import('./admin/renderAdminPanel'));

import { useLocation, useNavigate } from 'react-router';
import { setDeviceType, setOperatingSystem, setBrowser } from './store/schemas/mobileEnvironmentSlice';
import { ClientOnly } from '../library/client/serverCallHandler';
import IsolateContent from './mainapp/isolateContent';


// const LoadableLandingPage = loadable(() => import('./publicPages/lodable'))


const App = () => {
  const controller = useMemo(() => initController(), []);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const login = useSelector(state => state.auth.userActive)
  const phone_verify = useSelector((state) => state.auth?.userInfo?.phone_verify)
  const userInfo = useSelector(state => state.auth.userInfo)
  const selectedSchool = useSelector(state => state.mainapp.schoolSlice.selectedSchool)
  const location = useLocation()

  const schoolId = useMemo(() => {
    if (typeof window == 'undefined') return 0
    const url = window.location.pathname;
    const match = url.match(/\/app\/.+\/school\/(\d+)/);
    return match ? match[1] : null;
  }, []);





  useEffect(() => {
    if (process.env.NODE_COMPILE == 'split') {
      dispatch(getUser())
    }
  }, [])



  return (


    <>

      <GlobalLoadingOverlay />
      <ThunkNavigation />
      <Routes>
        <Route element={<IsolateContent />} >
          <Route path='/' element={<LandingPage />} />
          <Route path="/legal" element={<GDPR />} />
          <Route path='/school/:schoolId/campaign/:campaignId/:sharedStatus' element={<Campaign />} />
          <Route path='/campaignEnded' element={<ErrorPage errorMessage={<p>This Campaign Has <span>Ended</span></p>} />} />
          <Route path='/app/prerelease/:schoolId/:campaignId/:sharedStatus' element={<PreRelease />} />

          <Route path='/' element={<ClientOnly>{() => (<Homepage />)}</ClientOnly>} >
            <Route path='/app/completed/:schoolId/:campaignId/:sharedStatus' element={<Completed />} />


            {(login && (userInfo != undefined ? phone_verify : false)) ?
              <>
                {userInfo.permission != 'admin' ?
                    <>
                      <Route path='/school/:schoolId/campaign/:campaignId' element={<Campaign />} />
                        <Route path='/app' element={<MainDirectory />} >
                          <Route path='/app/overview/' element={<Overview />} >
                            <Route path='/app/overview/:schoolId/campaigns' element={<CampaignView />} />
                            <Route path='/app/overview/:schoolId/manage' element={<ManageView />} />
                            <Route path='/app/overview/:schoolId/requests' element={<RequestsView />} />


                          </Route>



                          {typeof window == 'undefined' ? null : controller[userInfo.permission].protectedRoutes(selectedSchool)}

                          {typeof window == 'undefined' ? null : controller[userInfo.permission].redirectProtected(schoolId)}


                        </Route>
                    </>
                  :
                  <Route path='*' element={<Navigate to={'/adminPanel/dashboard'} replace={true} />} />
                } 
                
                {typeof window == 'undefined' ? null : controller[userInfo.permission].redirectProtected(schoolId)}
              </>
              :
              <>
                <Route path='/' element={<LandingPage />} />
                <Route path='/app/onboarding' element={<Launch />} />

                <Route path='/app/onboarding' element={<Launch />} >

                  <Route path='/app/onboarding/student' element={<Student />} />
                  {/* <Route path='/app/onboarding/admin' element={<Admin />} /> */}
                  <Route path='/app/onboarding/login' element={<LoginForm />} />
                  {/* <Route path='/app/onboarding/signup/admin' element={<SignupForm type={"admin"} />} /> */}
                  <Route path='/app/onboarding/signup/student/:activationCode/:schoolName/:campaignName/:schoolId' element={<SignupForm type={"student"} />} />
                  <Route path='/app/onboarding/signup/student' element={<SignupForm type={"student"} />} />
                  <Route path='/app/onboarding/scan' element={<ScanCode />} />
                  <Route path='/app/onboarding/resetPassword' element={<ResetPassword />} />
                  <Route path='/app/onboarding/newPassword' element={<EnterNewPassword />} />


                </Route>
                <Route path='*' element={<Navigate to={'/app/onboarding'} />} ></Route>


              </>
            }


          </Route>

          <Route path='/404' element={<ErrorPage errorMessage={<p><span>Error 404</span> Page Not Found</p>} />} />
          <Route path='/505' element={<ErrorPage errorMessage={<p><span>Error 505</span> An Error Occurred</p>} />} />
        </Route>


        <Route path="/adminPanel/*" element={<ClientOnly>{() => (<RenderAdminPanel />)}</ClientOnly>} />


      </Routes>

    </>
  );
}


const initController = () => {


  return ({
    admin: {
      protectedRoutes: (selectedSchool) => {
        return <>
          <Route path='/app' element={<AdminPage />} />
          {selectedSchool != null ?
            <>
              <Route path='/app/new/school/:schoolId' element={<NewCampaign />} />
              <Route path='/app/edit/school/:schoolId/campaign/:campaignId' element={<NewCampaign />} />
              <Route path='/app/newprize/school/:schoolId/campaign/:campaignId' element={<NewPrize />} />
              <Route path='/app/editprize/school/:schoolId/campaign/:campaignId/prize/:prizeId' element={<NewPrize />} />
              <Route path='/app/payments/school/:schoolId/campaign/:campaignId' element={<Payments />} />
              <Route path='/app/winners/school/:schoolId/campaign/:campaignId' element={<Winners />} />

            </>
            :
            null
          }
          <Route path='/app/newschool' element={<NewSchool />} />
        </>
      },
      redirectProtected: (schoolId) => {
        return (<>
          <Route path='*' element={<Navigate to={schoolId == undefined ? "/app" : `/app/overview/${schoolId}/campaigns`} replace />} />
        </>
        )
      }
    },
    general_user: {
      protectedRoutes: (selectedSchool = null) => {
      },
      redirectProtected: (schoolId) => {
        return (<>
          <Route path='/app' element={<Navigate to={schoolId == undefined ? "/app/overview" : `/app/overview/${schoolId}/campaigns`} replace />} />
          <Route path='*' element={<Navigate to={schoolId == undefined ? "/app/overview" : `/app/overview/${schoolId}/campaigns`} replace />} />
        </>
        )
      },
      protectedRoutes: (selectedSchool, schoolId) => {
        return <>
          {selectedSchool != null ?
            <>
              <Route path='/app/join/school/:schoolId' element={<JoinCampaign />} />
              <Route path='/app/newProfile/school/:schoolId/campaign/:campaignId' element={<NewProfile editMode={false} />} />
              <Route path='/app/editProfile/school/:schoolId/campaign/:campaignId' element={<NewProfile editMode={true} />} />

            </>
            :
            null
          }
          <Route path='/app/join/school/:schoolId/code/:campaignCode' element={<JoinCampaign />} />
          <Route path='/app/onboarding/signup/student/:activationCode/:schoolName/:campaignName/:schoolId' element={<Redirect />} />
        </>
      }

    }
  })

}

export default App;
